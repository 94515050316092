import MpLogo from 'assets/images/default/logo.svg';
import DemoLogo from 'assets/images/demo/logo-black.svg';
import NccerLogo from 'assets/images/nccer/logo-black.png';
import GamsdLogo from 'assets/images/gamsd/logo.png';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import TeamsEliteLogo from 'assets/images/teamsElite/logo.png';
import AcsLogo from 'assets/images/acs/logo-black.png';
import CompassLogo from 'assets/images/compass/logo.png';
import React, { TdHTMLAttributes, useContext, useMemo } from 'react';
import { BasePayment } from 'store/types/Payment';
import { getAddressInfo, getFormattedDate, getStringValue } from 'util/Format';
import { getInvoiceModalDescription, getPrice } from 'util/Payment';
import PaymentStatus from 'store/enums/PaymentStatus';
import { ConfigContext } from 'components/ConfigGuard';
import OrderType from 'store/enums/OrderType';
import paymentStatusConfig from 'store/configs/PaymentStatusConfig';

import commonStyles from 'styles/common.module.scss';

interface PaymentInfoModalProps {
  payment: BasePayment;
  printContentId: string;
  printFrameId: string;
  isOrderPaymentConfirmation?: boolean;
  description?: string | React.ReactNode;
  poNumber?: string;
}

const PaymentDetailsPrintSection: React.FunctionComponent<PaymentInfoModalProps> = ({
  payment,
  printContentId,
  printFrameId,
  description,
  isOrderPaymentConfirmation,
  poNumber,
}) => {
  const { isNccerTheme, isGamsdTheme, isCompassTheme, isSmacnaTheme, isAcsTheme, isTeamsEliteTheme, isDemoTheme } =
    useContext(ConfigContext);
  const {
    orderDate,
    orderNumber,
    orderItems = [],
    billingAddress,
    billingAddressText = '',
    totalAmount,
    totalTax,
    totalShipping,
    paymentNumber,
    paymentDate,
    dueDate,
    status,
    paymentTypeText = '',
    billTo = '',
    ownerName,
    amountPaid = 0,
    amountToPay = 0,
    amountRemaining,
    billingMonth,
    applicationId,
    programId,
    type,
  } = payment;
  const infoTableTdProps: TdHTMLAttributes<HTMLTableDataCellElement> = {
    style: { padding: '8px 16px' },
    valign: 'top',
  };
  const infoTableTdLabelProps: TdHTMLAttributes<HTMLTableDataCellElement> = {
    ...infoTableTdProps,
    style: { ...infoTableTdProps.style, fontWeight: 700, whiteSpace: 'nowrap' },
  };
  const productsTableTdProps: TdHTMLAttributes<HTMLTableDataCellElement> = {
    style: { padding: '16px', borderBottom: '1px solid #e0e0e0' },
    align: 'left',
  };

  const modalDescription: React.ReactNode = useMemo(
    () => description || getInvoiceModalDescription(payment.status, isOrderPaymentConfirmation),
    [isOrderPaymentConfirmation, payment, description]
  );

  return (
    <>
      <div id={printContentId} className={commonStyles.hidden}>
        <div style={{ fontFamily: 'sans-serif' }}>
          <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={
                isNccerTheme
                  ? NccerLogo
                  : isGamsdTheme
                  ? GamsdLogo
                  : isCompassTheme
                  ? CompassLogo
                  : isSmacnaTheme
                  ? SmacnaLogo
                  : isAcsTheme
                  ? AcsLogo
                  : isTeamsEliteTheme
                  ? TeamsEliteLogo
                  : isDemoTheme
                  ? DemoLogo
                  : MpLogo
              }
              alt={'logo'}
              style={{ height: '120px' }}
            />
            {isNccerTheme && !isOrderPaymentConfirmation && (
              <p style={{ whiteSpace: 'pre-wrap', textAlign: 'right' }}>
                {'National Center for Construction Education and Research, Ltd.\n' +
                  '13614 Progress Boulevard\n' +
                  'Alachua, FL 32615'}
              </p>
            )}
          </div>
          <h2>
            {isOrderPaymentConfirmation
              ? 'Payment Confirmation'
              : type === OrderType.SalesOrd
              ? 'Sales Order'
              : 'Invoice'}
          </h2>
          <p style={{ lineHeight: '30px', display: 'inline-flex', flexDirection: 'column' }}>{modalDescription}</p>
          <div style={{ width: '100%' }}>
            <table style={{ width: '50%', float: 'left' }}>
              <tbody>
                {!!status && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Payment Status:</td>
                    <td {...infoTableTdProps}>{paymentStatusConfig[status || PaymentStatus.Processing].name}</td>
                  </tr>
                )}
                {!!dueDate && status !== PaymentStatus.Paid && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Due Date:</td>
                    <td {...infoTableTdProps}>{getFormattedDate(dueDate)}</td>
                  </tr>
                )}
                {!!paymentDate && status === PaymentStatus.Paid && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Payment Date:</td>
                    <td {...infoTableTdProps}>{getFormattedDate(paymentDate)}</td>
                  </tr>
                )}
                {!!paymentNumber && !isOrderPaymentConfirmation && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Payment Number:</td>
                    <td {...infoTableTdProps}>{paymentNumber}</td>
                  </tr>
                )}
                {!!paymentTypeText && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Payment Type:</td>
                    <td {...infoTableTdProps}>{paymentTypeText}</td>
                  </tr>
                )}
                {!!ownerName && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Customer:</td>
                    <td {...infoTableTdProps}>{ownerName}</td>
                  </tr>
                )}
                {!!billingMonth && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Billing Month:</td>
                    <td {...infoTableTdProps}>{billingMonth}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <table style={{ width: '50%', float: 'left' }}>
              <tbody>
                {!!applicationId && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Application Number:</td>
                    <td {...infoTableTdProps}>{applicationId}</td>
                  </tr>
                )}
                {!!programId && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Program ID:</td>
                    <td {...infoTableTdProps}>{programId}</td>
                  </tr>
                )}
                {!!orderDate && !isOrderPaymentConfirmation && (
                  <tr>
                    <td {...infoTableTdLabelProps}>{'Date'}</td>
                    <td {...infoTableTdProps}>{getFormattedDate(orderDate)}</td>
                  </tr>
                )}
                {!!orderNumber && (
                  <tr>
                    <td {...infoTableTdLabelProps}>{'Number'}</td>
                    <td {...infoTableTdProps}>{orderNumber}</td>
                  </tr>
                )}
                {!!poNumber && (
                  <tr>
                    <td {...infoTableTdLabelProps}>PO Number:</td>
                    <td {...infoTableTdProps}>{poNumber}</td>
                  </tr>
                )}
                {!!(billingAddress || billingAddressText || billTo) && (
                  <tr>
                    <td {...infoTableTdLabelProps}>Bill To:</td>
                    <td {...infoTableTdProps}>
                      <span>
                        {billTo && <span style={{ display: 'block', wordBreak: 'break-word' }}>{billTo}</span>}
                        {billingAddressText ? (
                          <span style={{ display: 'block', wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {billingAddressText}
                          </span>
                        ) : billingAddress ? (
                          getAddressInfo({ ...billingAddress, label: '' }, { style: { display: 'block' } })
                        ) : null}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <h3
            style={{
              textTransform: 'uppercase',
              borderBottom: '2px solid #163832',
              color: '#163832',
              paddingBottom: '8px',
              fontSize: '16px',
              display: 'inline-block',
              width: '100%',
            }}
          >
            {isOrderPaymentConfirmation || type === OrderType.SalesOrd ? 'Order Summary' : 'Invoice Summary'}
          </h3>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                {isOrderPaymentConfirmation && (
                  <th {...productsTableTdProps}>{type === OrderType.CustInvc ? 'Invoice #' : 'Order #'}</th>
                )}
                {isOrderPaymentConfirmation && <th {...productsTableTdProps}>Company Name</th>}
                {isOrderPaymentConfirmation && <th {...productsTableTdProps}>Invoice Date</th>}
                {isOrderPaymentConfirmation && <th {...productsTableTdProps}>Payment #</th>}
                {!isOrderPaymentConfirmation && <th {...productsTableTdProps}>Product Description</th>}
                {!isOrderPaymentConfirmation && <th {...productsTableTdProps}>Memo </th>}
                <th {...productsTableTdProps}>{isOrderPaymentConfirmation ? 'Amount' : 'Amount Each'}</th>
                {!isOrderPaymentConfirmation && <th {...productsTableTdProps}>Quantity</th>}
                <th {...productsTableTdProps} align={'right'}>
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map(
                (
                  {
                    itemId,
                    invoiceName,
                    description,
                    amount,
                    subtotal,
                    quantity,
                    amountEach,
                    orderDate,
                    paymentNumber,
                    ownerName,
                    memo,
                  },
                  index
                ) => (
                  <tr key={`product-item-${itemId}-${index}`}>
                    {isOrderPaymentConfirmation && <td {...productsTableTdProps}>{invoiceName}</td>}
                    {!isOrderPaymentConfirmation && <td {...productsTableTdProps}>{description}</td>}
                    {isOrderPaymentConfirmation && <td {...productsTableTdProps}>{getStringValue(ownerName)}</td>}
                    {isOrderPaymentConfirmation && <td {...productsTableTdProps}>{getFormattedDate(orderDate)}</td>}
                    {isOrderPaymentConfirmation && <td {...productsTableTdProps}>{getStringValue(paymentNumber)}</td>}
                    {!isOrderPaymentConfirmation && <td {...productsTableTdProps}>{getStringValue(memo)}</td>}
                    <td {...productsTableTdProps}>{getPrice(isOrderPaymentConfirmation ? amount : amountEach)}</td>
                    {!isOrderPaymentConfirmation && (
                      <td {...productsTableTdProps}>{getStringValue(quantity ? quantity.toString() : '')}</td>
                    )}
                    <td {...productsTableTdProps} align={'right'}>
                      {getPrice(subtotal)}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
            <span>
              {isOrderPaymentConfirmation || type === OrderType.SalesOrd ? 'Order Total: ' : 'Invoice Total: '}
              {getPrice(totalAmount)}
            </span>
          </div>
          <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
            <span>
              {'Tax Total: '}
              {getPrice(totalTax)}
            </span>
          </div>
          <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
            <span>
              {'Shipping Total: '}
              {getPrice(totalShipping)}
            </span>
          </div>
          {type === OrderType.CustInvc && (
            <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
              <span>
                {'Previous Payments / Credits: '}
                {getPrice(amountPaid)}
              </span>
            </div>
          )}
          <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
            <span>
              {'Amount Paid: '}
              {getPrice(amountToPay)}
            </span>
          </div>
          {status !== PaymentStatus.FullyBilled && (
            <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
              <span>
                {'Balance Due: '}
                {getPrice(amountRemaining)}
              </span>
            </div>
          )}
          {isNccerTheme && !isOrderPaymentConfirmation && type !== OrderType.SalesOrd && (
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {'If paying by check, please make sure the invoice number is on the check and send it to:\n' +
                '\n' +
                'NCCER\n' +
                'Attn: Accounts Receivable\n' +
                '13614 Progress Blvd\n' +
                'Alachua, FL 32615'}
            </p>
          )}
        </div>
      </div>
      <iframe id={printFrameId} className={commonStyles.hidden} title={'Order Confirmation'} />
    </>
  );
};
export default PaymentDetailsPrintSection;
