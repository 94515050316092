import { HttpService } from 'services/HttpService';
import { API_URL, conf, tenantConfig } from 'config';
import AuthService from 'services/AuthService';
import { httpRequestWrapper } from 'util/Request';
import Application from 'store/types/Application';
import ApplicationContent from 'store/types/ApplicationContent';
import ApplicationType from 'store/types/ApplicationType';
import { ApplicationAnswerData, ApplicationUploadedFile } from 'store/types/ApplicationAnswer';
import ApplicationStatus from 'store/enums/ApplicationStatus';
import ApplicationReview from 'store/types/ApplicationReview';
import ApplicationReviewAnswer from 'store/types/ApplicationReviewAnswer';
import { ApplicationReviewDecisionFormValues } from 'store/types/FormValues';
import FileInfo from 'store/types/FileInfo';
import ApplicationReviewType from 'store/enums/ApplicationReviewType';

type ApplicationsResponseData = Application[];
type ApplicationTypesResponseData = ApplicationType[];
type ReviewsResponseData = ApplicationReview[];

export interface ApplicationSubmitResponseData {
  isOk: boolean;
  messages: Array<{ questionId: string; message: string }>;
  status: ApplicationStatus;
}

class ApplicationsService {
  public static getUserApplications(): Promise<ApplicationsResponseData> {
    return new Promise<ApplicationsResponseData>(
      (resolve: (data: ApplicationsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationsResponseData>(
          HttpService.get(`${API_URL}/applications`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getApplicationTypes(): Promise<ApplicationTypesResponseData> {
    return new Promise<ApplicationTypesResponseData>(
      (resolve: (data: ApplicationTypesResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationTypesResponseData>(
          HttpService.get(`${API_URL}/applications/types`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static createApplication(typeId: string, campaignId?: string): Promise<Application> {
    return new Promise<Application>((resolve: (data: Application) => void, reject: (error: string) => void) => {
      httpRequestWrapper<Application>(
        HttpService.post(`${API_URL}/applications`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
          queryParams: { typeId, campaignId },
        }),
        resolve,
        reject
      );
    });
  }

  public static getApplication(id: string): Promise<ApplicationContent> {
    return new Promise<ApplicationContent>(
      (resolve: (data: ApplicationContent) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationContent>(
          HttpService.get(`${API_URL}/applications/${id}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateApplicationContent(applicationId: string, data: ApplicationAnswerData[]): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/applications/${applicationId}/answers`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static deleteApplication(applicationId: string): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.delete(`${API_URL}/applications/${applicationId}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getApplicationFile(applicationId: string, { id, name }: FileInfo): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/applications/${applicationId}/attachments/${id}`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
            [conf.filenameHeader]: name,
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static submitApplicationContent(
    applicationId: string,
    data: ApplicationAnswerData[]
  ): Promise<ApplicationSubmitResponseData> {
    return new Promise<ApplicationSubmitResponseData>(
      (resolve: (data: ApplicationSubmitResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationSubmitResponseData>(
          HttpService.post(`${API_URL}/applications/${applicationId}/submit`, {
            data,
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static uploadFile(applicationId: string, questionId: string, file: File): Promise<ApplicationUploadedFile> {
    const uploadedData = new FormData();

    uploadedData.append('file', file);
    return new Promise<ApplicationUploadedFile>(
      (resolve: (data: ApplicationUploadedFile) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationUploadedFile>(
          HttpService.post(`${API_URL}/applications/${applicationId}/attachments`, {
            data: uploadedData,
            queryParams: { questionId },
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getApplicationReviews(): Promise<ReviewsResponseData> {
    return new Promise<ReviewsResponseData>(
      (resolve: (data: ReviewsResponseData) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ReviewsResponseData>(
          HttpService.get(`${API_URL}/applications/reviews`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static getApplicationReview(id: string): Promise<ApplicationContent> {
    return new Promise<ApplicationContent>(
      (resolve: (data: ApplicationContent) => void, reject: (error: string) => void) => {
        httpRequestWrapper<ApplicationContent>(
          HttpService.get(`${API_URL}/applications/reviews/${id}`, {
            headers: {
              TenantId: tenantConfig.id,
              authorization: AuthService.getBearerToken(),
            },
          }),
          resolve,
          reject
        );
      }
    );
  }

  public static updateApplicationReview(
    reviewId: string,
    data: ApplicationReviewAnswer[],
    reviewType?: ApplicationReviewType
  ): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper(
        HttpService.post(`${API_URL}/applications/reviews/${reviewId}`, {
          data: { remarks: data, reviewType },
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static submitApplicationReview(reviewId: string, data: ApplicationReviewDecisionFormValues): Promise<null> {
    return new Promise<null>((resolve: (value: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.post(`${API_URL}/applications/reviews/${reviewId}/submit`, {
          data,
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getDisclosureFile(): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/applications/reviews/disclosure`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }

  public static getDisclosureTemplateFile(): Promise<null> {
    return new Promise<null>((resolve: (data: null) => void, reject: (error: string) => void) => {
      httpRequestWrapper<null>(
        HttpService.getFileDownload(`${API_URL}/applications/reviews/template`, {
          headers: {
            TenantId: tenantConfig.id,
            authorization: AuthService.getBearerToken(),
          },
        }),
        resolve,
        reject
      );
    });
  }
}
export default ApplicationsService;
