import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import NccerLogo from 'assets/images/nccer/logo-black.png';
import GamsdLogo from 'assets/images/gamsd/logo.png';
import CompassLogo from 'assets/images/compass/logo.png';
import SnaLogo from 'assets/images/sna/logo-black.png';
import AcsLogo from 'assets/images/acs/logo.png';
import TeamsEliteLogo from 'assets/images/teamsElite/logo.png';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import MemberplexLogo from 'assets/images/default/logo-black.svg';
import DemoLogo from 'assets/images/demo/logo-black.svg';
import { ConfigContext } from 'components/ConfigGuard';
import SidebarMenu from './SidebarMenu';
import SidebarMenuButton from './SidebarMenuButton';
import { getLocalStorageValue, setLocalStorageValue, SIDEBAR_COLLAPSED_LS_LABEL } from 'util/LocalStorage';
import { useWindowSize } from 'util/Window';

import styles from './Sidebar.module.scss';

interface SidebarProps {
  alwaysCollapsed?: boolean;
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({ alwaysCollapsed }) => {
  const { isMobile } = useWindowSize();
  const {
    isNccerTheme,
    isGamsdTheme,
    isCompassTheme,
    isSnaTheme,
    isSmacnaTheme,
    isAcsTheme,
    isTeamsEliteTheme,
    isDemoTheme,
    modulesSettings: {
      nccer: { portalHeader },
    },
  } = useContext(ConfigContext);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const setCollapsedValue = (value: boolean) => {
    if (!alwaysCollapsed) {
      setLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL, value);
    }
    setCollapsed(value);
  };

  useEffect(() => {
    if (!alwaysCollapsed) {
      const lsCollapsedValue: boolean | string = getLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL);

      if (lsCollapsedValue) {
        setCollapsed(true);
      } else if (lsCollapsedValue === '') {
        setLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL, false);
      }
      window.addEventListener('storage', syncCollapsedValue);
      return () => {
        window.removeEventListener('storage', syncCollapsedValue);
      };
    } else {
      setCollapsed(true);
    }
  }, [alwaysCollapsed]);

  useEffect(() => {
    setMenuVisible(!isMobile);
  }, [isMobile]);

  const syncCollapsedValue = () => {
    setCollapsed(!!getLocalStorageValue(SIDEBAR_COLLAPSED_LS_LABEL));
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const handleBarButtonClick = () => {
    if (isMobile) {
      setMenuVisible(!menuVisible);
    } else {
      setCollapsedValue(!collapsed);
    }
  };

  return (
    <aside
      className={classNames(styles.sidebar, { [styles.collapsed]: collapsed }, { [styles.menuVisible]: menuVisible })}
    >
      <div className={classNames(styles.sidebarHeader, styles.nccerSidebarHeader, styles.gamsdSidebarHeader)}>
        {isMobile && (
          <>
            {isNccerTheme ? (
              <div className={styles.nccerContainer}>
                <a href={'/'} className={styles.nccerLogo}>
                  <img src={NccerLogo} alt={'logo'} />
                </a>
                {portalHeader && <span className={styles.nccerTitle}>{portalHeader}</span>}
              </div>
            ) : (
              <a href={'/'} className={styles.logo}>
                <img
                  src={
                    isGamsdTheme
                      ? GamsdLogo
                      : isCompassTheme
                      ? CompassLogo
                      : isSnaTheme
                      ? SnaLogo
                      : isSmacnaTheme
                      ? SmacnaLogo
                      : isAcsTheme
                      ? AcsLogo
                      : isTeamsEliteTheme
                      ? TeamsEliteLogo
                      : isDemoTheme
                      ? DemoLogo
                      : MemberplexLogo
                  }
                  alt={'logo'}
                />
              </a>
            )}
          </>
        )}
        <SidebarMenuButton
          onClick={handleBarButtonClick}
          collapsed={collapsed}
          menuVisible={menuVisible}
          isMobile={isMobile}
        />
      </div>
      <div className={classNames(styles.menuViewWrapper)}>
        <SidebarMenu collapsed={collapsed && !isMobile} onItemClick={closeMenu} />
      </div>
    </aside>
  );
};
export default Sidebar;
