import { Button, Grid, IconButton, Link, TextField } from '@mui/material';
import Print from '@mui/icons-material/Print';
import Send from '@mui/icons-material/Send';
import classNames from 'classnames';
import { MembershipContext } from 'components/MembershipGuard';
import PaymentDetailsModal from 'components/payments/PaymentDetailsModal';
import usePaymentDetailsPrint from 'components/payments/PrintPaymentDetailsHook';
import SendPaymentModal from 'components/payments/SendPaymentModal';
import Table from 'components/shared/Table';
import React, { Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import SubscriptionStatus from 'store/enums/SubscriptionStatus';
import PaymentStatus from 'store/enums/PaymentStatus';
import { CurrentMembership } from 'store/types/Membership';
import { OrderPayment } from 'store/types/Payment';
import { FilterConfig, SortConfig, TableColumn } from 'store/types/Table';
import { ConfigContext } from 'components/ConfigGuard';
import reducer, {
  initialState,
  OrderHistoryTableAction,
  OrderHistoryTableActionType,
  OrderHistoryTableState,
} from './OrderHistoryTableReducer';
import { EMPTY_STRING_VALUE, getStringValue, NUMBER_DATE_FORMAT } from 'util/Format';
import { getFormattedOrdersList, getPaymentStatusLabel, getPrice, preselectedOrdersStatePropName } from 'util/Payment';
import { defaultGridItemProps } from 'util/Layout';
import TableCountLabel from 'components/shared/TableCountLabel';
import routes from 'store/configs/Routes';
import Company from 'store/types/Company';
import Modal from 'components/shared/Modal';
import OrderType from 'store/enums/OrderType';

import commonStyles from 'styles/common.module.scss';
import styles from './OrderHistoryTable.module.scss';

interface OrderHistoryTableProps {
  data?: OrderPayment[];
  loading?: boolean;
  filter: FilterConfig<OrderPayment>;
  openOrders?: boolean;
  companyColumnEnabled?: boolean;
  ownerColumnEnabled?: boolean;
  makePaymentRoute?: string;
  companyId?: Company['id'];
  salesOrders?: boolean;
}

const OrderHistoryTable: React.FunctionComponent<OrderHistoryTableProps> = ({
  data = [],
  loading = false,
  filter,
  openOrders = false,
  ownerColumnEnabled = false,
  companyColumnEnabled = false,
  makePaymentRoute = routes.makePersonalPayment,
  companyId,
  salesOrders,
}) => {
  const {
    isNccerTheme,
    isMemberplexTheme,
    isDemoTheme,
    modulesSettings: {
      payments: { enableSalesOrderHistory },
    },
  } = useContext(ConfigContext);
  const [poNumberModalOpen, setPONumberModalOpen] = useState<boolean>(false);
  const [poNumberValue, setPONumberValue] = useState<string>();
  const currentMembership: CurrentMembership = useContext(MembershipContext);
  const [{ sort, list = [], clickedItem, printedItem, emailInvoiceId, initialList = [], orderType }, dispatch] =
    useReducer<Reducer<OrderHistoryTableState, OrderHistoryTableAction>>(reducer, initialState);
  const printCallback = useCallback(() => {
    !poNumberModalOpen &&
      dispatch({
        type: OrderHistoryTableActionType.ClosePrint,
        payload: {},
      });
  }, [poNumberModalOpen]);
  const [printPayment, printPaymentComponent] = usePaymentDetailsPrint(
    printedItem,
    false,
    printCallback,
    undefined,
    poNumberValue
  );
  const history = useHistory();
  const { invoiceId = '' } = useParams<{ invoiceId: string }>();

  useEffect(() => {
    if (invoiceId && initialList.length && !clickedItem) {
      const foundPayment = initialList.find(({ orderNumber }) => orderNumber && orderNumber === invoiceId);

      if (foundPayment) {
        dispatch({
          type: OrderHistoryTableActionType.OpenDetails,
          payload: { clickedItem: foundPayment },
        });
        history.replace(routes.personalPayments);
      }
    }
  }, [initialList, clickedItem, history, invoiceId]);

  useEffect(() => {
    let newInitialList: OrderPayment[] = [];

    if (data.length) {
      newInitialList = getFormattedOrdersList(data, openOrders, salesOrders);
    }

    if (!enableSalesOrderHistory) {
      newInitialList = [...newInitialList].filter(({ type }) => type !== OrderType.SalesOrd);
    }

    if (!openOrders) {
      newInitialList = [...newInitialList].filter(
        ({ status, type }) => status === PaymentStatus.Paid || type === OrderType.SalesOrd
      );
    } else if (currentMembership.status === SubscriptionStatus.Pending) {
      newInitialList = [
        ...newInitialList,
        {
          status: PaymentStatus.Processing,
          totalAmount: 0,
          totalTax: 0,
          totalShipping: 0,
          amountRemaining: 0,
          invoiceName: 'Certification Fee',
          id: EMPTY_STRING_VALUE,
          orderNumber: EMPTY_STRING_VALUE,
          orderDate: new Date(Date.now()).toDateString(),
          billTo: '',
        },
      ];
    }
    dispatch({
      type: OrderHistoryTableActionType.SetInitialList,
      payload: { initialList: newInitialList },
    });
  }, [data, currentMembership, openOrders, salesOrders, enableSalesOrderHistory]);

  useEffect(() => {
    dispatch({
      type: OrderHistoryTableActionType.UpdateFilter,
      payload: { filter },
    });
  }, [filter]);

  useEffect(() => {
    if (printedItem) {
      setPONumberValue(printedItem.poNumber);
      isNccerTheme || isMemberplexTheme || isDemoTheme ? setPONumberModalOpen(true) : printPayment();
    }
  }, [printedItem, printPayment, isNccerTheme, isMemberplexTheme, isDemoTheme]);

  const handleOrderLinkClick = useCallback(
    (payment: OrderPayment) => () => {
      dispatch({
        type: OrderHistoryTableActionType.OpenDetails,
        payload: { clickedItem: payment },
      });
    },
    []
  );

  const handlePaymentModalClose = useCallback(() => {
    dispatch({
      type: OrderHistoryTableActionType.CloseDetails,
      payload: {},
    });
  }, []);

  const handleSortChange = useCallback((newSort: SortConfig) => {
    dispatch({
      type: OrderHistoryTableActionType.UpdateSort,
      payload: { sort: newSort },
    });
  }, []);

  const handlePrintClick = useCallback(
    (order: OrderPayment) => () => {
      dispatch({
        type: OrderHistoryTableActionType.OpenPrint,
        payload: { printedItem: order },
      });
    },
    []
  );

  const handleSendClick = useCallback(
    ({ id, type }: OrderPayment) =>
      () => {
        dispatch({
          type: OrderHistoryTableActionType.OpenEmailModal,
          payload: { emailInvoiceId: id, orderType: type },
        });
      },
    []
  );

  const handleSendModalClose = useCallback(() => {
    dispatch({
      type: OrderHistoryTableActionType.CloseEmailModal,
      payload: {},
    });
  }, []);

  const handleMakePaymentClick = useCallback(
    ({ id }: OrderPayment) =>
      () => {
        history.push({
          pathname: makePaymentRoute,
          state: { [preselectedOrdersStatePropName]: [id] },
        });
      },
    [makePaymentRoute, history]
  );

  const handlePONumberModalClose = useCallback(() => {
    setPONumberModalOpen(false);
    dispatch({
      type: OrderHistoryTableActionType.ClosePrint,
      payload: {},
    });
  }, []);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPONumberValue(e.target.value);
  }, []);

  const columns: Array<TableColumn<OrderPayment>> = useMemo(() => {
    const result: Array<TableColumn<OrderPayment>> = [
      {
        dataIndex: 'status',
        label: 'Status',
        sortable: true,
        align: 'center',
        render: (status: PaymentStatus) => getPaymentStatusLabel(status),
      },
      {
        dataIndex: 'orderNumber',
        label: openOrders ? 'Number' : 'Transaction Number',
        sortable: true,
        render: (orderNumber: number, record: OrderPayment) => (
          <Link
            className={classNames(commonStyles.link, { [styles.disabled]: record.status === PaymentStatus.Processing })}
            onClick={handleOrderLinkClick(record)}
          >
            {orderNumber}
          </Link>
        ),
      },
      {
        dataIndex: openOrders ? 'dueDate' : 'orderDate',
        label: openOrders ? 'Due Date' : 'Date',
        hidden: salesOrders,
        sortable: true,
        render: (date: string) => getStringValue(date, NUMBER_DATE_FORMAT),
      },
      {
        dataIndex: openOrders ? 'amountRemaining' : 'amountPaid',
        label: 'Amount',
        sortable: true,
        render: (amount: number, { status }: OrderPayment) =>
          status === PaymentStatus.Processing ? EMPTY_STRING_VALUE : getPrice(amount),
      },
      {
        key: 'email',
        label: 'Email',
        align: 'center',
        render: (_: any, record: OrderPayment) => (
          <IconButton
            color={'primary'}
            onClick={handleSendClick(record)}
            className={classNames({
              [styles.disabled]: record.status === PaymentStatus.Processing,
            })}
          >
            <Send />
          </IconButton>
        ),
      },
      {
        key: 'print',
        label: 'Print',
        align: 'center',
        render: (_: any, record: OrderPayment) => (
          <IconButton
            color={'primary'}
            onClick={handlePrintClick(record)}
            className={classNames({ [styles.disabled]: record.status === PaymentStatus.Processing })}
          >
            <Print />
          </IconButton>
        ),
      },
    ];

    if (companyColumnEnabled) {
      result.splice(2, 0, { dataIndex: 'organization', label: 'Organization', sortable: true });
    }
    if (openOrders) {
      result.splice(4, 0, {
        key: 'action',
        label: 'Action',
        align: 'center',
        render: (_: any, record: OrderPayment) => (
          <Button variant={'outlined'} color={'primary'} onClick={handleMakePaymentClick(record)}>
            {'Pay'}
          </Button>
        ),
      });
    }
    if (ownerColumnEnabled) {
      result.splice(2, 0, { dataIndex: 'ownerName', label: 'Owner', sortable: true });
    }
    return result;
  }, [
    openOrders,
    salesOrders,
    companyColumnEnabled,
    ownerColumnEnabled,
    handleOrderLinkClick,
    handleSendClick,
    handlePrintClick,
    handleMakePaymentClick,
  ]);

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid {...defaultGridItemProps}>
          <TableCountLabel viewCount={list.length} totalCount={initialList.length} loading={loading} />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <Table
            loading={loading}
            columns={columns}
            list={list}
            sort={sort}
            onSortChange={handleSortChange}
            showPagination={true}
          />
        </Grid>
      </Grid>
      {!!clickedItem && (
        <PaymentDetailsModal
          open={!!clickedItem}
          payment={clickedItem}
          title={clickedItem.type === OrderType.SalesOrd ? 'Sales Order' : ''}
          onClose={handlePaymentModalClose}
          makePaymentRoute={makePaymentRoute}
        />
      )}
      {!!emailInvoiceId && orderType && (
        <SendPaymentModal
          open={!!emailInvoiceId}
          invoiceId={emailInvoiceId}
          orderType={orderType}
          onClose={handleSendModalClose}
          companyId={companyId}
        />
      )}
      {!(isNccerTheme || isMemberplexTheme || isDemoTheme) && printPaymentComponent}
      <Modal
        open={poNumberModalOpen}
        onClose={handlePONumberModalClose}
        maxWidth={'xs'}
        title={'Enter PO Number'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handlePONumberModalClose}>
              {'Cancel'}
            </Button>
            <Button color={'primary'} variant={'contained'} onClick={printPayment}>
              {'Print'}
            </Button>
          </>
        }
      >
        <TextField label={'PO Number'} onChange={handleChange} value={poNumberValue} />
        {(isMemberplexTheme || isDemoTheme || isNccerTheme) && printPaymentComponent}
      </Modal>
    </>
  );
};
export default OrderHistoryTable;
