import React, { useContext, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppPublicRoutes from 'components/AppPublicRoutes';
import { ConfigContext } from 'components/ConfigGuard';
import RouteScrollToTop from 'components/layout/RouteScrollToTop';
import { HashRouter as Router } from 'react-router-dom';
import {
  acsTheme,
  compassTheme,
  demoTheme,
  gamsdTheme,
  memberplexTheme,
  nccerTheme,
  smacnaTheme,
  snaTheme,
  teamsEliteTheme,
} from 'util/Layout';
import { addDocumentInfo } from 'util/Layout';

const AppContent: React.FunctionComponent = () => {
  const siteConfig = useContext(ConfigContext);
  const {
    isNccerTheme,
    isMemberplexTheme,
    isDemoTheme,
    isGamsdTheme,
    isCompassTheme,
    isSnaTheme,
    isSmacnaTheme,
    isAcsTheme,
    isTeamsEliteTheme,
  } = siteConfig;
  const theme = useMemo(
    () =>
      isMemberplexTheme
        ? memberplexTheme
        : isDemoTheme
        ? demoTheme
        : isNccerTheme
        ? nccerTheme
        : isGamsdTheme
        ? gamsdTheme
        : isCompassTheme
        ? compassTheme
        : isSnaTheme
        ? snaTheme
        : isSmacnaTheme
        ? smacnaTheme
        : isAcsTheme
        ? acsTheme
        : isTeamsEliteTheme
        ? teamsEliteTheme
        : memberplexTheme,

    [
      isMemberplexTheme,
      isDemoTheme,
      isNccerTheme,
      isGamsdTheme,
      isCompassTheme,
      isSnaTheme,
      isSmacnaTheme,
      isAcsTheme,
      isTeamsEliteTheme,
    ]
  );

  useEffect(() => {
    addDocumentInfo(siteConfig);
  }, [siteConfig]);

  const content: React.ReactNode = (
    <Router>
      <RouteScrollToTop />
      <AppPublicRoutes />
    </Router>
  );

  return <ThemeProvider theme={theme}>{content}</ThemeProvider>;
};
export default AppContent;
