import { Button, TextField } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import GetApp from '@mui/icons-material/GetAppOutlined';
import { useSnackbar } from 'notistack';
import PaymentService from 'services/api/PaymentService';
import { defaultSnackbarErrorProps, getButtonLoadingProps } from 'util/Layout';
import PaymentDetailsSection from './PaymentDetailsSection';
import usePaymentDetailsPrint from 'components/payments/PrintPaymentDetailsHook';
import Modal from 'components/shared/Modal';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'store/configs/Routes';
import PaymentStatus from 'store/enums/PaymentStatus';
import { ModalProps } from 'store/types/ComponentProps';
import { BasePayment } from 'store/types/Payment';
import { ConfigContext } from 'components/ConfigGuard';
import { getInvoiceModalDescription, preselectedOrdersStatePropName } from 'util/Payment';

import styles from './PaymentDetailsModal.module.scss';

interface PaymentDetailsModalProps extends Pick<ModalProps, 'open'> {
  onClose?: () => void;
  payment: BasePayment;
  title?: string;
  description?: React.ReactNode;
  makePaymentActionVisible?: boolean;
  actionButtonsEnabled?: boolean;
  makePaymentRoute?: string;
  actions?: React.ReactNode;
  isOrderPaymentConfirmation?: boolean;
  companyPayment?: boolean;
  showDescription?: boolean;
}

const PaymentDetailsModal: React.FunctionComponent<PaymentDetailsModalProps> = ({
  open,
  payment,
  onClose,
  title,
  description = '',
  actionButtonsEnabled = true,
  makePaymentActionVisible = true,
  actions = null,
  makePaymentRoute = routes.makePersonalPayment,
  isOrderPaymentConfirmation = false,
  companyPayment = false,
  showDescription = true,
}) => {
  const history = useHistory();
  const { isGamsdTheme, isCompassTheme, isNccerTheme, isMemberplexTheme, isDemoTheme } = useContext(ConfigContext);
  const [poNumberModalOpen, setPONumberModalOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const modalDescription: React.ReactNode = useMemo(
    () => description || getInvoiceModalDescription(payment.status, isOrderPaymentConfirmation),
    [isOrderPaymentConfirmation, payment, description]
  );
  const [poNumberValue, setPONumberValue] = useState<string>();
  const [printPayment, printPaymentComponent] = usePaymentDetailsPrint(
    payment,
    isOrderPaymentConfirmation,
    undefined,
    modalDescription,
    poNumberValue
  );
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const { id, status, hasTestDetail = false } = payment;

  useEffect(() => {
    if (payment && payment.poNumber) {
      setPONumberValue(payment.poNumber);
    }
  }, [payment]);

  const handleMakePaymentClick = useCallback(() => {
    history.push({
      pathname: makePaymentRoute,
      state: { [preselectedOrdersStatePropName]: [id] },
    });
  }, [id, makePaymentRoute, history]);

  const handleDownloadDetailsClick = useCallback(() => {
    if (hasTestDetail) {
      setFileLoading(true);
      PaymentService.getInvoiceDetailsFile(id)
        .then(() => {
          setFileLoading(false);
        })
        .catch((errorMessage: string) => {
          enqueueSnackbar(errorMessage, defaultSnackbarErrorProps);
          setFileLoading(false);
        });
    }
  }, [id, hasTestDetail, enqueueSnackbar]);

  const handlePONumberModalClose = useCallback(() => {
    setPONumberModalOpen(false);
    setPONumberValue(payment?.poNumber || '');
  }, [payment]);

  const handlePONumberModalOpen = useCallback(() => {
    setPONumberModalOpen(true);
  }, []);

  const printButtonHandler = useMemo(
    () => (isNccerTheme || isMemberplexTheme || isDemoTheme ? handlePONumberModalOpen : printPayment),
    [isNccerTheme, isMemberplexTheme, isDemoTheme, handlePONumberModalOpen, printPayment]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPONumberValue(e.target.value);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        maxWidth={'md'}
        title={
          <div className={styles.title}>
            <CheckCircle
              className={
                isGamsdTheme ? styles.headerIconGamsd : isCompassTheme ? styles.headerIconCompass : styles.headerIcon
              }
            />
            {title || 'Invoice'}
          </div>
        }
        actions={
          <>
            <Button color={'primary'} variant={'outlined'} onClick={printButtonHandler}>
              {'Print'}
            </Button>
            {hasTestDetail && (
              <Button
                color={'primary'}
                variant={'outlined'}
                onClick={handleDownloadDetailsClick}
                className={styles.downloadButton}
                disabled={fileLoading}
                startIcon={<GetApp color={'inherit'} />}
                {...getButtonLoadingProps(fileLoading)}
              >
                {'Download Details'}
              </Button>
            )}
            {actionButtonsEnabled && (
              <>
                {actions}
                {makePaymentActionVisible &&
                  status &&
                  (status === PaymentStatus.Unpaid ||
                    status === PaymentStatus.Overdue ||
                    status === PaymentStatus.PendingBilling) && (
                    <Button color={'secondary'} variant={'contained'} onClick={handleMakePaymentClick}>
                      {'Make a Payment'}
                    </Button>
                  )}
              </>
            )}
          </>
        }
      >
        {showDescription && <p className={styles.description}>{modalDescription}</p>}
        <PaymentDetailsSection
          payment={payment}
          isOrderPaymentConfirmation={isOrderPaymentConfirmation}
          companyPayment={companyPayment}
        />
        {!(isMemberplexTheme || isDemoTheme || isNccerTheme) && printPaymentComponent}
      </Modal>
      <Modal
        open={poNumberModalOpen}
        onClose={handlePONumberModalClose}
        maxWidth={'xs'}
        title={'Enter PO Number'}
        actions={
          <>
            <Button color={'secondary'} variant={'outlined'} onClick={handlePONumberModalClose}>
              {'Cancel'}
            </Button>
            <Button color={'primary'} variant={'contained'} onClick={printPayment}>
              {'Print'}
            </Button>
          </>
        }
      >
        <TextField label={'PO Number'} onChange={handleChange} value={poNumberValue} />
        {(isMemberplexTheme || isDemoTheme || isNccerTheme) && printPaymentComponent}
      </Modal>
    </>
  );
};
export default PaymentDetailsModal;
