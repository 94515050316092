import React, { TdHTMLAttributes, useContext } from 'react';
import AcsLogo from 'assets/images/acs/logo-black.png';
import CompassLogo from 'assets/images/compass/logo.png';
import MpLogo from 'assets/images/default/logo.svg';
import DemoLogo from 'assets/images/demo/logo.svg';
import GamsdLogo from 'assets/images/gamsd/logo.png';
import NccerLogo from 'assets/images/nccer/logo-black.png';
import SmacnaLogo from 'assets/images/smacna/logo.png';
import TeamsEliteLogo from 'assets/images/teamsElite/logo.png';
import { ConfigContext } from 'components/ConfigGuard';
import { ExpenseReport } from 'store/types/Expense';
import { getFormattedDate, getFullDate } from 'util/Format';
import { getPrice } from 'util/Payment';

import commonStyles from 'styles/common.module.scss';

interface ExpenseReportPrintProps {
  expenseReport: ExpenseReport;
  printContentId: string;
  printFrameId: string;
}

const ExpenseReportPrintSection: React.FunctionComponent<ExpenseReportPrintProps> = ({
  expenseReport,
  printContentId,
  printFrameId,
}) => {
  const { isNccerTheme, isGamsdTheme, isCompassTheme, isSmacnaTheme, isAcsTheme, isTeamsEliteTheme, isDemoTheme } =
    useContext(ConfigContext);

  const { date, status, transactionNumber, amount, memo, customer, expenses } = expenseReport;
  const { street1, street2, city, stateRegion, postalCode, country } = customer.address;

  const reportTableTdProps: TdHTMLAttributes<HTMLTableCellElement> = {
    style: { padding: '8px 16px' },
    valign: 'top',
  };
  const reportTableTdLabelProps: TdHTMLAttributes<HTMLTableCellElement> = {
    ...reportTableTdProps,
    style: { ...reportTableTdProps.style, fontWeight: 700, verticalAlign: 'top', whiteSpace: 'nowrap' },
  };
  const expenseTableTdProps: TdHTMLAttributes<HTMLTableCellElement> = {
    align: 'left',
    style: { borderBottom: '1px solid #e0e0e0', lineHeight: '20px', padding: '16px', verticalAlign: 'top' },
  };

  return (
    <>
      <div id={printContentId} className={commonStyles.hidden}>
        <div style={{ fontFamily: 'sans-serif' }}>
          <div style={{ marginBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
            <img
              src={
                isNccerTheme
                  ? NccerLogo
                  : isGamsdTheme
                  ? GamsdLogo
                  : isCompassTheme
                  ? CompassLogo
                  : isSmacnaTheme
                  ? SmacnaLogo
                  : isAcsTheme
                  ? AcsLogo
                  : isTeamsEliteTheme
                  ? TeamsEliteLogo
                  : isDemoTheme
                  ? DemoLogo
                  : MpLogo
              }
              alt={'logo'}
              style={{ height: '120px' }}
            />
          </div>

          <h2>{'Expense Report'}</h2>

          <div style={{ width: '100%' }}>
            <table style={{ width: '50%', float: 'left' }}>
              <tbody>
                <tr>
                  <td {...reportTableTdLabelProps}>Date:</td>
                  <td {...reportTableTdProps}>{getFormattedDate(date.toString())}</td>
                </tr>
                <tr>
                  <td {...reportTableTdLabelProps}>Status:</td>
                  <td {...reportTableTdProps}>{status}</td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: '50%', float: 'left' }}>
              <tbody>
                <tr>
                  <td {...reportTableTdLabelProps}>Ref #:</td>
                  <td {...reportTableTdProps}>{transactionNumber}</td>
                </tr>
                <tr>
                  <td {...reportTableTdLabelProps}>Customer:</td>
                  <td {...reportTableTdProps}>
                    <div style={{ lineHeight: '20px' }}>
                      {customer.firstName} {customer.lastName}
                    </div>
                    <div style={{ lineHeight: '20px' }}>{street1}</div>
                    <div style={{ lineHeight: '20px' }}>{street2}</div>
                    <div style={{ lineHeight: '20px' }}>
                      {city} {stateRegion} {postalCode}
                    </div>
                    <div>{country}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style={{ fontWeight: 'bold', paddingBottom: '12px' }}>Purpose</div>
          <div style={{ whiteSpace: 'pre-line' }}>{memo}</div>

          <h3
            style={{
              borderBottom: '2px solid #163832',
              color: '#163832',
              display: 'inline-block',
              fontFamily: 'arial',
              fontSize: '16px',
              paddingTop: '16px',
              paddingBottom: '8px',
              textTransform: 'uppercase',
              width: '100%',
            }}
          >
            Expenses
          </h3>

          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th {...expenseTableTdProps}>Date</th>
                <th {...expenseTableTdProps}>Category</th>
                <th {...expenseTableTdProps}>Memo</th>
                <th {...expenseTableTdProps} align={'right'}>
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense) => {
                const {
                  id: expenseId,
                  date: expenseDate,
                  category,
                  quantity,
                  rate,
                  amount: expenseAmount,
                  memo: expenseMemo,
                } = expense;
                return (
                  <tr key={`expense-${expenseId}`}>
                    <td {...expenseTableTdProps}>{expenseDate && getFullDate(expenseDate.toString())}</td>
                    <td {...expenseTableTdProps}>{category?.name}</td>
                    <td {...expenseTableTdProps}>{expenseMemo}</td>
                    <td {...expenseTableTdProps} align={'right'}>
                      {getPrice(Number(expenseAmount))}
                      {category?.isQtyRateCategory && (
                        <div>
                          <div>Qty {quantity} /</div>
                          <div> Rate {getPrice(Number(rate))}</div>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div style={{ fontWeight: 700, fontSize: '22px', textAlign: 'right', padding: '16px 16px 16px 0' }}>
            {getPrice(Number(amount))}
          </div>
        </div>
      </div>

      <iframe id={printFrameId} className={commonStyles.hidden} title={`Expense Report ${transactionNumber}`} />
    </>
  );
};
export default ExpenseReportPrintSection;
