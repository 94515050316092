import { Button, Grid, Tooltip } from '@mui/material';
import { ConfigContext } from 'components/ConfigGuard';
import { UserContext } from 'components/UserGuard';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PaymentService from 'services/api/PaymentService';
import SiteModule from 'store/enums/SiteModule';
import { OrderPayment } from 'store/types/Payment';
import { QuickLinkConfigItem } from 'store/types/SiteConfig';
import { defaultGridContainerProps, defaultGridItemProps } from 'util/Layout';
import useRequest from 'hooks/useRequest';
import HomeAnnouncementsCard from './HomeAnnouncementsCard';
import HomeCreditsSection from './HomeCreditsSection';
import HomeMembershipCard from './HomeMembershipCard';
import HomeNccerNoticeCard from './HomeNccerNoticeCard';
import HomeOpenOrdersSection from './HomeOpenOrdersSection';
import HomeOrdersCard from './HomeOrdersCard';
import HomeNavigationCard from './HomeNavigationCard/HomeNavigationCard';
import DonationTermsModal from 'components/donation/DonationPaymentPageView/DonationTermsModal';
import { tenantConfig } from 'config';
import { isModuleAvailable } from 'util/User';

import styles from './HomePage.module.scss';

interface CardsConfigItem {
  component: React.ReactNode;
  module?: SiteModule;
  key: string;
  fullWidth?: boolean;
}

const HomePage: React.FunctionComponent = () => {
  const { id = '', nccerCardNumber, functions, userPermissions } = useContext(UserContext);
  const { addNccerCardNumberUrl = '' } = tenantConfig;
  const {
    isNccerTheme,
    isCompassTheme,
    isMemberplexTheme,
    isDemoTheme,
    isSmacnaTheme,
    quickLinks,
    enabledModules,
    modulesSettings: {
      home: { enableNavigationSection, enableNotes, navigationLinks },
      nccer: { enableCardNumber },
    },
  } = useContext(ConfigContext);
  const ordersRequestData = useRequest<OrderPayment[]>(id && !isNccerTheme ? PaymentService.getOrdersList : undefined);
  const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] = useState<boolean>(false);
  const quickLinksConfig: QuickLinkConfigItem[] = useMemo(
    () =>
      isNccerTheme && !nccerCardNumber
        ? quickLinks.filter(({ label }) => label !== 'Registry' && label !== 'Module Testing')
        : quickLinks,
    [isNccerTheme, nccerCardNumber, quickLinks]
  );
  const navigationCards: React.ReactNode[] = useMemo(
    () =>
      (navigationLinks || [])
        .filter(({ type }) => isModuleAvailable(type, functions, userPermissions))
        .map(({ title, icon, path }) => (
          <Grid {...defaultGridItemProps} sm={true} key={`navigation-card-${title}`}>
            <HomeNavigationCard title={title} icon={icon} path={path} />
          </Grid>
        )),
    [functions, navigationLinks, userPermissions]
  );

  const cardsConfig: CardsConfigItem[] = useMemo(() => {
    const cards: CardsConfigItem[] = [
      {
        component: <HomeAnnouncementsCard />,
        module: SiteModule.Announcements,
        key: 'announcements',
        fullWidth: true,
      },
      // hid it because:
      // not being used right now, need to avoid the error with too many requests
      // {
      //   component: <HomeCommunitiesCard variation={'chapters'} />,
      //   module: SiteModule.Chapters,
      //   key: 'chapters',
      // },
      // {
      //   component: <HomeCommunitiesCard variation={'committees'} />,
      //   module: SiteModule.Committees,
      //   key: 'committees',
      // },
    ];

    (isMemberplexTheme || isDemoTheme || isSmacnaTheme) &&
      cards.splice(2, 0, {
        component: (
          <Grid {...defaultGridContainerProps}>
            {enabledModules.includes(SiteModule.PersonalPayments) && (
              <Grid {...defaultGridItemProps} lg={6}>
                {!ordersRequestData.error ? <HomeOpenOrdersSection {...ordersRequestData} /> : null}
              </Grid>
            )}
            <Grid {...defaultGridItemProps} lg={6}>
              <HomeCreditsSection />
            </Grid>
          </Grid>
        ),
        key: 'tiles',
        fullWidth: enabledModules.includes(SiteModule.PersonalPayments),
      });

    (isMemberplexTheme || isDemoTheme) &&
      cards.splice(3, 0, {
        component: <HomeOrdersCard {...ordersRequestData} />,
        module: SiteModule.PersonalPayments,
        key: 'orders',
      });

    enableNavigationSection &&
      cards.unshift({
        component: <Grid {...defaultGridContainerProps}>{navigationCards}</Grid>,
        key: 'navigation',
        fullWidth: true,
      });

    enableNotes &&
      cards.splice(
        1,
        0,

        {
          component: <HomeNccerNoticeCard />,
          module: SiteModule.Notice,
          key: 'notice',
          fullWidth: true,
        }
      );

    !isSmacnaTheme &&
      cards.splice(
        1,
        0,

        {
          component: <HomeMembershipCard />,
          module: SiteModule.Membership,
          key: 'membership',
        }
      );

    return cards;
  }, [
    isMemberplexTheme,
    isDemoTheme,
    isSmacnaTheme,
    enabledModules,
    ordersRequestData,
    enableNavigationSection,
    navigationCards,
    enableNotes,
  ]);

  const content = useMemo(() => {
    const fullPageContent: React.ReactNode[] = [];
    const firstColContent: React.ReactNode[] = [];
    const secondColContent: React.ReactNode[] = [];

    cardsConfig.forEach(({ fullWidth = false, component, module, key }) => {
      if (component && (!module || enabledModules.includes(module))) {
        (fullWidth
          ? fullPageContent
          : firstColContent.length <= secondColContent.length
          ? firstColContent
          : secondColContent
        ).push(
          <Grid {...defaultGridItemProps} key={key}>
            {component}
          </Grid>
        );
      }
    });
    return (
      <>
        {fullPageContent}
        <Grid {...defaultGridItemProps} lg={6}>
          <Grid {...defaultGridContainerProps}>{firstColContent}</Grid>
        </Grid>
        <Grid {...defaultGridItemProps} lg={6}>
          <Grid {...defaultGridContainerProps}>{secondColContent}</Grid>
        </Grid>
      </>
    );
  }, [enabledModules, cardsConfig]);

  const handleTermsAndConditionsModalOpen = useCallback(() => {
    setTermsAndConditionsModalOpen(true);
  }, []);

  const handleTermsAndConditionsModalClose = useCallback(() => {
    setTermsAndConditionsModalOpen(false);
  }, []);

  return (
    <>
      <Grid {...defaultGridContainerProps}>
        <Grid {...defaultGridItemProps}>
          <Grid {...defaultGridContainerProps} alignItems={'baseline'} justifyContent={'space-between'}>
            <Grid {...defaultGridItemProps} sm={true}>
              <nav className={styles.quickLinks}>
                <span className={styles.label}>{'Quick Links:'}</span>
                {quickLinksConfig.map(({ label, link, tooltip = '' }, i) => (
                  <Tooltip title={tooltip} arrow={true} key={`quick-link-${i}`} placement={'top'}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      href={link}
                      target={'_blank'}
                      className={styles.quickLinkButton}
                    >
                      {label}
                    </Button>
                  </Tooltip>
                ))}
              </nav>
            </Grid>
            {isCompassTheme && (
              <Grid {...defaultGridItemProps} sm={'auto'}>
                <Button variant={'contained'} color={'primary'} onClick={handleTermsAndConditionsModalOpen}>
                  {'Review Terms&Conditions'}
                </Button>
              </Grid>
            )}
            {isNccerTheme && enableCardNumber && (
              <Grid {...defaultGridItemProps} lg={'auto'} className={styles.cardNumberInfo}>
                {nccerCardNumber ? (
                  <span className={styles.label}>{`NCCER Card Number: ${nccerCardNumber}`}</span>
                ) : (
                  addNccerCardNumberUrl && (
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      type={'submit'}
                      href={addNccerCardNumberUrl + encodeURIComponent(id)}
                    >
                      {'Add NCCER Card Number'}
                    </Button>
                  )
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {content}
      </Grid>
      {isCompassTheme && (
        <DonationTermsModal open={termsAndConditionsModalOpen} onClose={handleTermsAndConditionsModalClose} />
      )}
    </>
  );
};
export default HomePage;
